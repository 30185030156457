import { NavLink, useParams } from "react-router-dom";
import PageDescription from "../../layout/page-description";
import companyService from "../../services/company.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader2 from "../utilities/Loader2";
import { getUsers } from "../../jason-proof-of-concept/users/actions/get-users";
import { getAuthToken } from "../../services/auth-header";
import TableNeogen from "../../layout/table-neogen";
import ButtonNeoGen from "../../layout/button-neogen";
import { getProcessflowProgresses } from "../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getDataFromProgressData } from "../../jason-proof-of-concept/wizard/wizard-container";
import { getEstimatedPayoutFromData } from "../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { getUserCompanies } from "../../user-companies/actions/get-user-companies";
import { useMemo, useState } from "react";
import { AddUserToCompanyModal } from "./add-user-to-company-modal";
import { deleteUserCompany } from "../../user-companies/actions/delete-user-company";
import Swal from "sweetalert2";
import { EditUserModal } from "./modals/edit-user-modal";
import roleGroupService from "../../services/role-group.service";
import BreadCrumbs from "../../layout/breadcrumbs";

export const Company = () => {
    const { companyId: companyIdParam } = useParams();
    const [editUserId, setEditUserId] = useState<string | undefined>();
    const authToken = getAuthToken();
    if (!companyIdParam) {
        throw new Error("companyId is required");
    }
    const companyId = parseInt(companyIdParam);
    const [showModal, setShowModal] = useState(false);

    const companyQuery = useQuery(["company", { id: companyId }], async () => {
        const companyResponse = await companyService.getOne(companyId);
        return companyResponse;
    });
    const company = companyQuery.data?.data;

    const usersQuery = useQuery(["users", "company", companyId], async () => {
        const userCompanies = await getUserCompanies({ authToken, filters: { where: { companyId: companyId } } });
        const userIds = userCompanies.map((userCompany) => userCompany.ercUserId);
        const filters = { where: { id: { inq: userIds } } };
        const users = await getUsers({
            authToken,
            filters: { ...filters, include: ["companyRoleGroups", "userCompanies"] },
        });
        return { users, userCompanies };
    });
    const users = useMemo(() => usersQuery.data?.users || [], [usersQuery.data?.users]);
    const userCompanies = usersQuery.data?.userCompanies || [];

    const userId = company?.ownedById;

    const progressQuery = useQuery(["billing-page-calcs", { userId }, authToken], async () => {
        if (!userId) {
            return {};
        }
        const group = 7;
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { userId, group },
        });
        const data = getDataFromProgressData(usersProgress.data as any);
        const estimatedAmount = getEstimatedPayoutFromData({ data });
        return { usersProgress, data, estimatedAmount };
    });

    const removeUserMutation = useMutation({
        mutationFn: async (userId: string) => {
            const [userCompany] = await getUserCompanies({
                authToken,
                filters: { where: { companyId, ercUserId: userId } },
            });
            await deleteUserCompany({ authToken, id: userCompany.id });
        },
    });

    const { usersProgress, data, estimatedAmount } = progressQuery.data || {};
    const percentage = Math.round((usersProgress?.data?.percentageComplete || 0) * 100);

    const userToEdit = useMemo(() => users.find((user) => user.id === editUserId), [users, editUserId]);

    const companyRolesQuery = useQuery(["companyRoles", { userId, companyId }], async () => {
        const response = await roleGroupService.getAll();
        const roles = response?.data || [];
        const companyRoles = roles.filter((role) => role.isForCompany === 1);
        return companyRoles;
    });
    const companyRoles = companyRolesQuery.data || [];

    return !company ? (
        <Loader2 />
    ) : (
        <div>
            {showModal && (
                <AddUserToCompanyModal
                    onClose={() => setShowModal(false)}
                    companyId={companyId}
                    onUserAdded={() => {
                        usersQuery.refetch();
                        setShowModal(false);
                    }}
                    companyRoles={companyRoles as any[]}
                />
            )}
            {userToEdit && (
                <EditUserModal
                    onClose={() => setEditUserId(undefined)}
                    user={userToEdit}
                    companyId={companyId}
                    onUserUpdated={() => {
                        usersQuery.refetch();
                        setEditUserId(undefined);
                    }}
                    currentRole={
                        companyRoles.filter((r) =>
                            userCompanies
                                .filter((uc) => uc.ercUserId === userToEdit.id && uc.companyId === companyId)
                                .map((uc) => uc.roleGroupId)
                                .includes(r.id || -1),
                        )[0]
                    }
                    companyRoles={companyRoles as any[]}
                />
            )}
            <BreadCrumbs
                pages={[
                    {
                        name: "Companies",
                        href: "/companies",
                    },
                    {
                        name: company.name || "(no-name)",
                        href: `/companies/${companyId || ""}`,
                        current: true,
                    },
                ]}
            />
            <PageDescription
                title={`Company: ${company.name}`}
                description="Manage your company details and settings"
            ></PageDescription>
            <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-4">
                    <div className="flex-1 rounded-xl shadow-md bg-white p-4">
                        <div className="flex flex-row justify-between items-center">
                            <h2>Application</h2>
                            <NavLink
                                to={`/companies/${companyId}/application`}
                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <span className="fa fa-folder-open mr-3"></span>
                                Open Application
                            </NavLink>
                        </div>
                        <div>Progress: {percentage}%</div>
                    </div>
                    <div className="flex-1 rounded-xl shadow-md bg-white p-4">
                        <h2>Company details</h2>
                        <div>Name: {company.name}</div>
                        <div>Website: {company.website}</div>
                    </div>
                </div>
                <div className="flex-1 rounded-xl shadow-md bg-white p-4">
                    <div className="flex flex-row justify-between items-center mb-2">
                        <div>
                            <h2>Users</h2>
                            <p className="text-gray-400">Manage the users who are in your company</p>
                        </div>
                        <ButtonNeoGen
                            onClick={() => {
                                setShowModal(true);
                            }}
                        >
                            Add user
                        </ButtonNeoGen>
                    </div>
                    {users.length > 0 ? (
                        <TableNeogen
                            entries={users.map((user) => ({
                                id: user.id,
                                name: `${user.firstName} ${user.lastName}`,
                                email: user.email,
                                phone: user.phone,
                                roles: (user.companyRoleGroups || [])
                                    .filter((rg) => {
                                        const included = userCompanies
                                            .filter((uc) => uc.ercUserId === user.id)
                                            .map((uc) => uc.roleGroupId)
                                            .includes(rg.id);
                                        return included;
                                    })
                                    .map((roleGroup) => roleGroup.name)
                                    .join(", "),
                            }))}
                            actionsAreDropDown
                            actions={[
                                {
                                    label: "Edit user",
                                    onClick: (userId: string) => {
                                        setEditUserId(userId);
                                    },
                                },
                                {
                                    label: "Remove user",
                                    disabled: (id) => {
                                        return id === userId;
                                    },
                                    onClick: async (userId: string) => {
                                        Swal.fire({
                                            title: "Are you sure you want to remove this user?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonText: "Yes, remove",
                                            confirmButtonColor: "#d33",
                                            cancelButtonColor: "#3085d6",
                                        }).then(async (result) => {
                                            if (result.value) {
                                                await removeUserMutation.mutateAsync(userId);
                                                usersQuery.refetch();
                                                Swal.fire({
                                                    title: "User removed",
                                                    text: "The user has been removed from the company",
                                                    icon: "success",
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                });
                                            }
                                        });
                                    },
                                },
                            ]}
                        />
                    ) : (
                        <div>
                            <p className="text-center text-md m-4">No users in this company yet</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
