import { ReactNode } from "react";
import SelectT from "react-tailwindcss-select";
import { SelectValue } from "react-tailwindcss-select/dist/components/type";
import { RoleGroup } from "../../typings/api/role-group";
import { Label } from "../label";

export type Option = {
    value: string | number;
    label: string;
};

export const Select = ({
    value,
    onChange,
    options,
    label,
    noMarginBottom,
    placeholder,
}: {
    value: string | string[] | null | RoleGroup;
    onChange: (value?: string | string[] | undefined | null) => any;
    options: Option[];
    label?: ReactNode;
    noMarginBottom?: boolean;
    placeholder?: string;
}) => {
    return (
        <>
            {label && (
                <div className={noMarginBottom ? "mb-0" : "mb-1"}>
                    <Label text={label} />
                </div>
            )}
            <SelectT
                placeholder={placeholder || ""}
                isClearable
                classNames={{
                    menuButton: () =>
                        "flex bg-white dark:bg-gray-900 dark:text-gray-300 border " +
                        "border-gray-300 dark:border-gray-900 rounded-md shadow-sm " +
                        "text-left cursor-default focus:outline-none focus:ring-1 " +
                        "focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm " +
                        "overflow-hidden placeholder-gray-400 dark:placeholder-gray-400 ",
                    menu:
                        "absolute z-10 w-full dark:bg-gray-900 bg-white shadow-lg " +
                        " border border-gray-300 dark:border-gray-900 rounded py-1 " +
                        "mt-1.5 text-sm text-gray-700",

                    searchBox:
                        "w-full dark:bg-gray-800 py-2 pl-8 text-sm text-gray-500 dark:text-gray-300 " +
                        "bg-gray-100 border border-gray-300 rounded " +
                        "focus:border-gray-300 focus:ring-0 focus:outline-none ",
                    listItem: () =>
                        "dark:text-gray-300 block transition duration-200 px-2 " +
                        "py-2 cursor-pointer select-none truncate rounded text-gray-500 " +
                        "hover:bg-blue-100 hover:text-gray-500 hover:dark:text-gray-900 ",
                }}
                isSearchable
                primaryColor="white"
                value={options.find((o) => o.value === value) as SelectValue}
                onChange={(v) => {
                    const value = v ? (Array.isArray(v) ? v.map((s) => s.value || null) : v.value || null) : null;
                    onChange(value as any);
                }}
                options={options as any[]}
            />
        </>
    );
};
