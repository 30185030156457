import { makeRequest, parseSchema } from "../../shared/utils";
import { User, userSchema } from "../domain/user";
import { userResponseSchema } from "./get-users";

export const createUser = async ({ authToken, data }: { authToken: string; data: Partial<User> }) => {
    const response = await makeRequest({
        method: "post",
        path: `/users`,
        authToken,
        data,
    });

    const userDto = parseSchema(userResponseSchema, response.data);

    const user = parseSchema(userSchema, {
        ...userDto,
        company: userDto.company || undefined,
        name: userDto.name || undefined,
    });

    return user;
};
